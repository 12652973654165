import React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './article-preview.module.css'

const ArticlePreview = ({ posts }) => {
  const { language } = useI18next()
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  // const RandomKey = (length) => {
  //   var result = ''
  //   var characters =
  //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  //   var charactersLength = characters.length
  //   for (var i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  //   }
  //   return result
  // }

  return (
    // {posts.map((post) => {
    //   return (
    //     <li key={`${RandomKey(5)}_${post.slug}`}>
    //       <Link
    //         to={`/blog-post/${post.slug}`}
    //         language={language}
    //         className={styles.link}
    //       >
    //         <GatsbyImage alt="" image={post.heroImage.gatsbyImageData} />
    //         <h2 className={styles.title}>{post.title}</h2>
    //       </Link>
    //       <div
    //         dangerouslySetInnerHTML={{
    //           __html: post.description.childMarkdownRemark.html,
    //         }}
    //       />
    //       <div className={styles.meta}>
    //         <small className="meta">{post.publishDate}</small>
    //         <Tags tags={post.tags} />
    //       </div>
    //     </li>
    //   )
    // })}
    <div class="flex flex-col space-y-16">
      {posts.map((post) => (
        <div class="grid grid-cols-1 gap-6 md:grid-cols-4">
          {post?.heroImage?.gatsbyImageData && (
            <GatsbyImage
              alt=""
              image={post.heroImage.gatsbyImageData}
              className="rounded-xl w-full h-40 col-span-1 bg-center"
              loading="lazy"
            />
          )}

          <div class="col-span-1 md:col-span-3">
            <p class="mb-2 -mt-1 text-sm font-normal text-gray-500">
              {post.publishDate}
            </p>
            <Link
              to={`/blog-post/${post.slug}`}
              language={language}
              className={styles.link}
            >
              <h2 class="mb-2 text-xl font-extrabold leading-snug text-gray-800 hover:text-brand">
                {post.title}
              </h2>
            </Link>

            <div
              class="mb-3 text-sm font-normal text-gray-500"
              dangerouslySetInnerHTML={{
                __html: post.description.childMarkdownRemark.html,
              }}
            />

            {post.tags?.length > 0 && (
              <div className="flex flex-row gap-2 mt-4">
                {post.tags.map((tag) => (
                  <small
                    key={tag}
                    className="rounded-lg px-2 py-1 bg-slate-100 text-gray-500"
                  >
                    {tag}
                  </small>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ArticlePreview
