import React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'

import ArticlePreview from '../components/articlePreview'
import Container from '../components/container'

function BlogIndex({ data, location, pageContext }) {
  const { t, languages, language } = useI18next()
  const posts = get(data, 'allContentfulBlogPost.nodes')

  const { numPages, currentPage } = pageContext

  return (
    <Layout location={location} logoType="full">
      <Seo title="Blog" />
      {/* <Hero title="Blog" /> */}
      <Container>
        <h2 class="pt-6 pb-8 mb-12 text-2xl font-extrabold leading-tight text-gray-900 border-b border-gray-200 md:text-4xl">
          {t('all_articles')}
        </h2>

        <ArticlePreview posts={posts} />
        {/* dummy pager */}

        <div class="flex flex-col items-center justify-center pt-12 mt-12 space-x-0 space-y-2 border-t border-gray-200 md:space-x-2 md:space-y-0 md:flex-row gap-2">
          {currentPage &&
            currentPage !== 1 &&
            (currentPage === 2 ? (
              <Link to={`/blog/`} className="underline hover:text-brand">
                Previous Posts
              </Link>
            ) : (
              <Link
                to={`/blog/${currentPage - 1}`}
                className="underline hover:text-brand"
              >
                Previous Posts
              </Link>
            ))}
          {currentPage && currentPage !== numPages && (
            <Link
              to={`/blog/${currentPage + 1}`}
              className="underline hover:text-brand"
            >
              Next Posts
            </Link>
          )}
        </div>
        <nav></nav>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery(
    $language: String!
    $locale: String!
    $skip: Int!
    $limit: Int!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulBlogPost(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
